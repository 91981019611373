<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="p-4" v-if="!validateResetPasswordOTPView">
            <CCardBody>
              <CForm @submit.prevent="resetPassword">
                <h1>Forgot Password?</h1>
                <p class="text-muted">
                  Please Enter Your Email for password reset.
                </p>
                <CInput
                  placeholder="Please Enter Your Email Address"
                  autocomplete="username email"
                  v-model.lazy="email"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton
                      color="primary"
                      class="px-4"
                      type="submit"
                      :disabled="resetPasswordLoading || !resetFormIsValid"
                    >
                      <span v-if="resetPasswordLoading"
                        ><loader-icon
                          size="1.5x"
                          class="custom-class"
                        ></loader-icon
                        >Please Wait</span
                      >
                      <span v-else>Reset Password</span>
                    </CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0" :to="{ name: 'Login' }"
                      >Sign In?</CButton
                    >
                    <CButton
                      color="link"
                      class="d-lg-none"
                      href="https://my.onecentral.ng/agent/register"
                      target="_blank"
                      >Register now!</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
          <CCard class="p-4" v-if="validateResetPasswordOTPView">
            <CCardBody>
              <CForm @submit.prevent="verifyOtp">
                <h1>Verify OTP</h1>
                <p class="text-muted">
                  Please Verify Your Password Token.
                </p>
                <CInput
                  placeholder="Please The Enter The Token Sent to your email"
                  autocomplete="otp"
                  v-model.trim="otp"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>

                <CRow>
                  <CCol col="6" class="text-left">
                    <CButton
                      color="primary"
                      class="px-4"
                      type="submit"
                      :disabled="loading || !formIsValid"
                    >
                      <span v-if="loading"
                        ><loader-icon
                          size="1.5x"
                          class="custom-class"
                        ></loader-icon
                        >Please Wait</span
                      >
                      <span v-else>Verify</span>
                    </CButton>
                  </CCol>
                  <CCol col="6" class="text-right">
                    <CButton color="link" class="px-0" :to="{ name: 'Login' }"
                      >Sign In?</CButton
                    >
                    <CButton
                      color="link"
                      class="d-lg-none"
                      href="https://my.onecentral.ng/agent/register"
                      target="_blank"
                      >Register now!</CButton
                    >
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { UserService } from "@/services/user.service";
import Swal from "sweetalert2";
export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      otp: "",
      resetPasswordLoading: false,
      loading: false,
      validateResetPasswordOTPView: false,
      e: "",
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    resetFormIsValid() {
      return this.email !== "";
    },
    formIsValid() {
      return this.otp !== "";
    },
  },
  methods: {
    async resetPassword() {
      this.resetPasswordLoading = true;
      try {
        const sendOtp = await UserService.resetPassword(this.email);
        this.resetPasswordLoading = false;
        this.e = sendOtp.data.email;
        Swal.fire({
          title: "Success",
          text: sendOtp.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.validateResetPasswordOTPView = true;
        this.email = "";
        console.log(sendOtp);
      } catch (error) {
        this.resetPasswordLoading = false;
        console.log(error.response);
        let err = error.response.data;

        Swal.fire({
          title: "Error!",
          text: err.data,
          icon: "error",
          confirmButtonText: "Close",
        });
        this.email = "";
        this.validateResetPasswordOTPView = false;
      }
    },
    async verifyOtp() {
      let data = {
        type: "reset-password",
        email: this.e,
        otp: this.otp,
      };

      this.loading = true;
      try {
        const verifiedResponse = await UserService.verifyResetPasswordOTP(data);
        this.loading = false;
        this.$store.commit(
          "setChangePasswordToken",
          verifiedResponse.data.password_reset_token
        );
        Swal.fire({
          title: "Success",
          text: verifiedResponse.response_message,
          icon: "success",
          timer: 3000,
          confirmButtonText: "Close",
          showConfirmButton: false,
        });
        this.$router.push({ name: "ChangePassword" });
      } catch (error) {
        this.loadingVerifyResetPwdOTP = false;
        console.log(error.response);
        let err = error.response.data;
        // let errorBag = {
        //   code: err.response_code,
        //   message: err.response_message,
        //   data: err.data,
        // };
        Swal.fire({
          title: "Error!",
          text: err.data,
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    },
  },
};
</script>
